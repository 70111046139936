<div class="login-interactions" *transloco="let t">
  <div>
    <span class="hr3">{{ t('appKey') }} </span>
    <span class="hr4">{{ t('portalKey') }}</span>
  </div>

  <form [formGroup]="loginForm">
    @if (isAuth()) { @if (tid() === '5ddfe769-152f-4061-94da-8178693464af') {
    <mat-form-field appearance="fill">
      <mat-label>{{ t('impersonateKey') }}</mat-label>
      <input matInput formControlName="impersonate" type="email" />
    </mat-form-field>
    }

    <mat-form-field appearance="fill">
      <mat-label>{{ t('environmentsKey') }}</mat-label>
      <mat-select
        placeholder="Placeholder"
        name="item"
        formControlName="environment"
      >
        <mat-option *ngFor="let item of environments()" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ t('companiesKey') }}</mat-label>
      <mat-select
        placeholder="Placeholder"
        name="item"
        formControlName="company"
      >
        <mat-option *ngFor="let item of companies()" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill"
      ><mat-label>{{ t('portalTypeKey') }}</mat-label>
      <mat-select
        placeholder="Placeholder"
        name="item"
        formControlName="selectedLogin"
        data-cy="cySelectLogin"
      >
        <mat-option
          *ngFor="let item of loginOptions | callback : filterLogin"
          [value]="item"
          data-cy="cyPortalType"
        >
          {{ loginOptionMapping[item] | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      class="login"
      id="iLogin"
      type="button"
      (click)="loginInBc()"
      [disabled]="loading()"
      data-cy="cyLogin"
    >
      {{ t('singInKey') }}
    </button>

    } @else {
    <button
      mat-raised-button
      class="login365"
      id="iLoginO365"
      (click)="loginAzure()"
      type="button"
      [disabled]="loading()"
      data-cy="cyLogin365"
    >
      {{ t('signIn365Key') }}
    </button>
    }
  </form>
</div>
